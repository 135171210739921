<template>
  <div>
    <!-- Menu cerrado -->
    <v-card-title v-if="valvula === -1 && sensorSelectedOnMap === null" class="py-2 px-1">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-subtitle>
            <v-row>
              <v-col style="font-weight: normal;">
                <v-icon left color="teal"
                  :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'mt-0'">mdi-information-outline
                </v-icon>
                <span>Selecciona en el plano de la finca una válvula o</span>
                <span class="d-block d-sm-inline" :class="$vuetify.breakpoint.smAndDown ? 'ml-8' : ''"> sensor para
                  visualizar</span>
              </v-col>
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-title>
    <!-- Menu abierto -->
    <v-card v-else :class="this.$vuetify.breakpoint.mobile ? 'pa-0 ma-0 mb-2' : 'pa-1'">
      <v-card-title>
        <span v-if="!$vuetify.breakpoint.smAndDown" class="headline">Remote</span>
        <span
          :style="{ fontSize: $vuetify.breakpoint.smAndDown ? '18px' : '15px', color: 'rgb(1, 99, 99)', marginLeft: '16px' }">{{
      name_remote }}</span>
        <v-spacer></v-spacer>
        <!-- Info del remote -->
        <v-chip outlined small color="gray" class="mr-1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs" style="display: flex; align-items: center;">
                <v-icon color="gray" small>mdi-information-slab-circle-outline</v-icon>
              </div>
            </template>
            <div style="display: flex; flex-direction: column; align-items: flex-start;">
              <div style="display: flex; justify-content: center; width: 100%;">
                <v-icon small left color="white">mdi-identifier</v-icon>
                <span style="font-size: 0.7rem; text-align: right; flex-grow: 1;">{{ remoteId.toUpperCase() }}</span>
              </div>
              <div style="display: flex; justify-content: center; width: 100%;">
                <v-icon small left color="white">mdi-cellphone-information</v-icon>
                <span style="font-size: 0.7rem; text-align: right; flex-grow: 1;">{{ version }}</span>
              </div>
            </div>
          </v-tooltip>
        </v-chip>
        <!-- Nivel de la bateria -->
        <v-chip outlined small :color="battery_value < 20 ? 'red darken-1' : 'teal darken-2'" class="mr-1"
          v-if="battery_value >= 0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs" style="display: flex; align-items: center;">
                <v-icon :color="battery_value < 20 ? 'red' : 'teal darken-2'" small>{{ batteryClass }}</v-icon>
                <span :class="{ 'red-text': battery_value < 20, 'teal-text': battery_value >= 20 }">{{ battery_value
                  }}%</span>
              </div>
            </template>
            <span>
              <v-icon small left color="white">mdi-clock-outline</v-icon>
              <span style="font-size: 0.875rem;">{{ battery_timestamp }}</span>
            </span>
          </v-tooltip>
        </v-chip>
        <!-- Botón cerrar -->
        <v-icon @click="closeButtonClick()">mdi-window-close</v-icon>
      </v-card-title>
      <v-card-text>
        <!-- Sensores -->
        <v-subheader>
          <span>SENSORES</span>
        </v-subheader>
        <v-divider class="mt-n2"></v-divider>
        <!--Gráficas con slider para desplazar-->
        <template v-if="show_progress">
          <span class="d-flex flex-column align-center my-6">
            <v-progress-circular size="120" width="5" indeterminate color="teal darken-3"
              class="my-5"></v-progress-circular>
          </span>
        </template>
        <template v-if="sensores_asociados === true && show_progress === false">
          <v-slide-group v-model="model" class="my-4 mb-2">
            <v-slide-item v-for="(chart, index) in CHART_CARDS" :key="index">
              <div>
                <ChartCards :title="chart.title" :unidad="chart.unidad" :ultimo_valor="chart.ultimoValor"
                  :fechas="fechas" :data="chart.data" :loading="loading_chart_data" :color="chart.color"
                  :is-selected="chart.id === id_sensor" />
              </div>
            </v-slide-item>
          </v-slide-group>
        </template>
        <template v-else-if="sensores_asociados === false && show_progress === false">
          <div class="d-flex flex-column align-center my-6">
            <v-chip outlined color="primary" class="ma-2" pill>
              <v-icon left>
                mdi-water-thermometer-outline
              </v-icon>
              <span style="font-size: 14px;"> No existen sensores asociados al
                remote
              </span>
            </v-chip>
          </div>
        </template>
        <!-- Valvulas -->
        <v-subheader>
          <span>VÁLVULAS</span>
        </v-subheader>
        <v-divider class="mt-n2"></v-divider>
        <!--Botón por cada válvula que se encuentre-->
        <div class="my-4" row>
          <template v-if="filteredValvulas.length > 0">
            <v-slide-group v-model="model" class="my-4 mb-2">
              <v-slide-item v-for="valvula in filteredValvulas" :key="valvula.id">
                <v-btn @click="handleValveButtonClick(valvula.id)"
                  :class="{ 'valvula-selected': valvula.id === is_selected_valvula_id }" text x-large elevation="3"
                  class="mx-3 my-2">
                  <div class="d-flex flex-column align-center">
                    <img :src="getImagePath(valvula.current_state)" :alt="getImageAlt(valvula.current_state)"
                      style="width: 50px; height: 55px;">
                    <div class="text-caption mt-3"
                      :style="{ 'text-align': 'center', 'text-transform': 'capitalize', 'color': valvula.current_state === 1 ? 'rgb(5, 100, 100)' : 'rgb(108, 107, 107)' }">
                      {{ valvula.nombre }}
                    </div>
                  </div>
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          </template>
          <template v-else>
            <div class="d-flex flex-column align-center my-6">
              <v-chip outlined color="teal" class="ma-2" pill>
                <v-icon left>
                  mdi-pipe-valve
                </v-icon>
                <span style="font-size: 14px;"> No existen valvulas asociadas al
                  remote
                </span>
              </v-chip>
            </div>
          </template>
        </div>
      </v-card-text>
      <!-- Dialogo de la valvula  llamando al componente valvulascontrol para cuando se seleccione un boton de la valvula -->
      <v-dialog v-model="dialog_valve" max-width="350px">
        <ValvulasControl @close-dialog="closeDialogValveClick" :key="selectedValvula.id"
          :selectedValvula="selectedValvula" />
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import ChartCards from '@/components/ChartCards.vue'
import ValvulasControl from '@/components/ValvulasControl.vue'
import axios from "axios";
import valve_closed from "@/assets/valve_closed.png";
import valve_opened from "@/assets/valve_opened.png";
export default {
  components: {
    ChartCards,
    ValvulasControl,
  },
  props: ['remoteId', 'sensores', 'sensorSelectedOnMap', "valvula", "valvulas"],
  data() {
    //Remote
    const name_remote = null;
    const selectedValvula = {};
    //Sensores
    let id_sensor = -1;
    let sensor;
    if (this.sensorSelectedOnMap != null) {
      sensor = this.sensorSelectedOnMap;
      id_sensor = this.sensorSelectedOnMap.id
    }
    //Valvulas
    let valvulas_abiertas = [];
    this.valvulas.map((el) => {
      if (el.current_state >= 1) valvulas_abiertas.push(el);
    });

    //Graficos
    const fechas = [];
    const CHART_CARDS = [];

    return {
      //Remote
      name_remote,
      battery_value: 0,
      battery_timestamp: 0,
      version: null,
      //Valvulas
      is_selected_valvula_id: null,
      valveName: this.valvula.nombre,
      valveId: this.valvula.id,
      currentState: this.valvula.current_state || 0,
      valvulas_abiertas,
      mqttFail: false,
      mqttSuccess: false,
      selectedValvula,
      isAuto: false,
      is_button_disabled: false,
      //Sensores
      id_sensor,
      sensor,
      historico: null,
      ultimoValor: null,
      ultimoRegistro: null,
      sensores_asociados: false,
      //Diseños
      progress_loader: true,
      readonly: true,
      valveLoader: false,
      show_battery_details_on_hover: false,
      //Dialog
      dialog_valve: false,
      model: null,
      //Graficos
      loading_chart_data: false,
      fechas,
      CHART_CARDS,
      show_progress: false,
    };
  },
  watch: {
    // Función que se ejecuta cada vez que los sensores cambian
    sensores: function (newVal, oldVal) {
      if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
        this.sensores.find(sensor => {
          if (sensor.id == this.id_sensor) {
            this.sensor = sensor;
            this.ultimoValor = sensor.ultimoValor;
            this.ultimoRegistro = sensor.ultimoRegistro;
            this.historico = sensor.historico;
          }
        });
      }
    },

    // Watch que se ejecuta cada vez que se pulsa sobre un sensor
    sensorSelectedOnMap: function (newVal) {
      this.sensor = newVal;
      this.id_sensor = newVal.id;
      this.getSensorDataFromRemoteIdBetweenTimes();
      this.getRemoteBatteryFromRemoteId();
    },

    //Watch para revisar si hay algún cambio en las válvulas, de encontrarlo actualiza la vista
    valvulas: function (newVal, oldVal) {
      let valvulas_abiertas = [];
      newVal.map((el) => {
        if (el.current_state >= 1) {
          valvulas_abiertas.push(el);
        }
        oldVal.map((elOld) => {
          if (el.id === elOld.id) {
            if (el.current_state !== elOld.current_state) {
              this.$emit("updateElectrovalvulas");
            }
          }
        });
        newVal.find((el) => {
          if (el.id === this.valvula.id) {
            // this.currentState = el.current_state;
            this.switchState = el.current_state > 0 ? true : false;
            this.isAuto = el.is_auto;
          }
        });
      });
      this.valvulas_abiertas = valvulas_abiertas;
    },

    // Watch que se ejecuta cada vez que se pulsa sobre una valvula en el mapa
    valvula: function (newVal) {
      this.is_selected_valvula_id = newVal.id;
      this.valveName = newVal.nombre;
      this.readonly = true
      this.currentState = newVal.current_state;
      this.switchState = newVal.current_state > 0 ? true : false;
      //TODO: deberia ser = a this.getIsAuto()
      this.isAuto = newVal.is_auto;

      this.getSensorDataFromRemoteIdBetweenTimes();
      this.getRemoteBatteryFromRemoteId();
    },

  },
  methods: {
    //Para limpiar cada vez que cambie de valvula
    clearValues() {
      this.CHART_CARDS = [];
      this.battery_value = 0;
      this.battery_timestamp = 0;
      this.mqttFail = false;
      this.mqttSuccess = false;
      this.isAuto = false;
      this.is_button_disabled = false;
      this.name_remote = null;
    },

    // Para obtener el nombre y bateria del remote
    getRemoteBatteryFromRemoteId() {
      axios
        .post(process.env.VUE_APP_API_URL + "/getRemoteBatteryFromRemoteId", {
          id_remote: this.remoteId,
        })
        .then((res) => {
          this.name_remote = res.data.nombre;

          const timestamp_raw = res.data.battery_timestamp;
          const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'UTC', };

          this.battery_timestamp = new Date(timestamp_raw).toLocaleString('es-ES', options);

          this.version = res.data.version;

          if (this.version == "1.1") {
            if (res.data.battery_level >= 0 && res.data.battery_level < 3500) {
              this.battery_value = 0;
            } else {
              this.battery_value = (0 + (100 - 0) * (res.data.battery_level - 3347) / (4095 - 3347)).toFixed(0);
            }
          } else if (this.version == "1.4") {
            if (res.data.battery_level > 2460) {
              this.battery_value = (0.1509 * res.data.battery_level - 311.32).toFixed(0);
              if (this.battery_value > 100) {
                this.battery_value = 100;
              }
            } else if (res.data.battery_level <= 2460 && res.data.battery_level > 2372) {
              this.battery_value = (0.4545 * res.data.battery_level - 1058.2).toFixed(0);
            } else if (res.data.battery_level <= 2372 && res.data.battery_level > 2279) {
              this.battery_value = (0.1612 * res.data.battery_level - 362.58).toFixed(0);
            } else {
              this.battery_value = 0;
            }
          }

        })
        .catch((error) => {
          console.error('Error al obtener los datos del remote:', error);
        });
    },

    // Para obtener la valvula seleccionada
    handleValveButtonClick(valvulaId) {
      this.is_selected_valvula_id = valvulaId;
      this.selectedValvula = this.valvulas.find(valvula => valvula.id === valvulaId);
      if (this.selectedValvula) {
        this.dialog_valve = true;
        this.readonly = true;
      }
    },

    // Para cerrar el dialogo de la valvula
    closeDialogValveClick() {
      this.dialog_valve = false;
    },

    //Para cerrar el card del Remote
    closeButtonClick() {
      this.$emit('close-card');
    },

    // Para obtener las imágenes para los botones
    getImagePath(currentState) {
      return currentState >= 1 ? valve_opened : valve_closed;
    },

    // Para obtener el alt de las imágenes para los botones
    getImageAlt(currentState) {
      return currentState >= 1 ? 'valve_opened' : 'valve_closed';
    },

    // Para obtener los sensores según el remote
    getSensorDataFromRemoteIdBetweenTimes() {
      this.clearValues();
      this.show_progress = true;
      axios
        .post(process.env.VUE_APP_API_URL + "/getSensorDataFromRemoteIdBetweenTimes", {
          id_remote: this.remoteId,
          fecha_inicio: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
          fecha_fin: new Date().toISOString().split('T')[0],
        })
        .then((res) => {
          this.CHART_CARDS = [];
          for (const sensor_received of res.data) {
            if (sensor_received.id_tipo_sensor != 19 && sensor_received.current_value != -1) {
              this.CHART_CARDS.push(
                {
                  id: sensor_received.id,
                  title: sensor_received.nombre,
                  data: sensor_received.data,
                  unidad: sensor_received.unidades,
                  color: sensor_received.color_front_end,
                  ultimoValor: sensor_received.resumen.ultimo_valor
                }
              )

            }
          }
          this.sensores_asociados = this.CHART_CARDS.length > 0;

        })
        .catch(() => {

        })
        .finally(() => {
          this.show_progress = false;
        })
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    //Para sacar las valvulas según el id_remote
    filteredValvulas() {
      return this.valvulas.filter(valvula => valvula.id_remote === this.remoteId);
    },
    batteryClass() {
      if (this.battery_value < 30) {
        return "mdi-battery-alert";
      } else if (this.battery_value < 60) {
        return "mdi-battery-50";
      } else if (this.battery_value < 80) {
        return "mdi-battery-80";
      } else {
        return "mdi-battery";
      }
    },
  },

  beforeMount() {
    this.clearValues();
    // this.getSensorDataFromRemoteIdBetweenTimes();
  },
};
</script>

<style scoped>
.row .v-btn {
  margin-right: 10px;
  margin-bottom: 10px;
  min-width: 140px;
  height: 140px;
  font-size: 24px;
}

.valvula-selected {
  border: 2px solid rgb(0, 121, 107);
  box-shadow: 0 0 30px rgba(17, 40, 102, 0.62)
}

.red-text {
  color: red;
}

.teal-text {
  color: teal;
}
</style>